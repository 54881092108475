import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { ReactComponent as Transition1 } from '../assets/transition1.svg'
import React, { useState, useEffect } from 'react'
import Title from '../components/Title'
import MoreLink from '../components/MoreLink'
import { ReactComponent as Solar } from '../assets/solar-panel.svg'
import { ReactComponent as Structural } from '../assets/structural-engineering.svg'
import { ReactComponent as Pisos } from '../assets/pisos.svg'
import { ReactComponent as Rural } from '../assets/rural.svg'
import { ReactComponent as Architecture } from '../assets/architecture.svg'
import Tracking from '../tracking'
import useTidioChat from '../hooks/useTidioChat'
import ContactFormModal from '../components/ContactFormModal'

export default function Services () {
  const [contactFormModalShow, setContactFormModalShow] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()

    function reveal () {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 50
        if (elementTop < window.innerHeight - elementVisible) {
          reveals[i].classList.add('active')
        }
      }
    }
    window.addEventListener('scroll', reveal)

    // To check the scroll position on page load
    reveal()
  }, [])
  useTidioChat()
  return (
    <div>
      <ContactFormModal showModal={contactFormModalShow} setShowModal={setContactFormModalShow} />
      <NavBar />
      <main className='pt-24'>
        <h1 className='pb-16 text-6xl md:text-8xl font-bold leading-tight text-center'>Servicios</h1>
        <div className='relative -mt-12 lg:-mt-24' />
        <Transition1 />

        <section className='bg-white py-16 sm:py-32'>
          <div className='flex flex-col md:flex-row items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center mb-4 sm:mb-0 px-3 md:p-6 text-center sm:text-left basis-2/3'>
              <Title text='Arquitectura' />
              <div className='text-lg pt-4'>
                <p className='text-gray-600 text-justify sm:text-left'>
                  Si eres de los que valora el arte. Si buscas impacto visual y movimiento en Bluehaus te propondremos las mejores ideas. Somos expertos en imaginar, amamos crear conceptos que se adapten a tus gustos.<br /><br />
                </p>
                <MoreLink setContactFormModalShow={setContactFormModalShow}>
                  <div className='text-gray-600 text-justify sm:text-left  mb-4'>
                    <p className='font-bold'>¿Qué hacemos?</p>
                    <ul className='list-disc ml-8'>
                      <li>Diseño de Vivienda</li>
                      <li>Planos. Bocetos. Sketches</li>
                      <li>Obra nueva y ampliaciones</li>
                      <li>Visualizaciones 3D y Renders</li>
                      <li>Licencias de obra menor y mayor</li>
                    </ul>
                  </div>
                </MoreLink>
              </div>
            </div>
            <div className='flex justify-center w-full p-2 basis-1/3'>
              <Architecture className='w-72 pt-4 sm:pt-0' />
            </div>
          </div>
        </section>

        <section className='bg-bh-white py-16 sm:py-32'>
          <div className='flex flex-col md:flex-row-reverse items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center mb-4 sm:mb-0 px-3 md:p-6 text-center sm:text-left basis-2/3'>
              <Title text='Casas Rurales. Casitas de Pueblo' />
              <div className='text-lg pt-4'>
                <p className='text-gray-600 text-justify sm:text-left'>
                  Vender las propiedades en mal estado es lo más habitual. No obstante, en Bluehaus somos partidarios de reformarlas y mejorarlas para que realmente puedan ser disfrutadas.<br /><br />
                </p>
                <MoreLink setContactFormModalShow={setContactFormModalShow}>
                  <p className='text-gray-600 text-justify sm:text-left  mb-4'>No hay nada más inspirador que jugar con los materiales y el entorno para hacer florecer estas viviendas manteniendo su esencia.<br /><br />Tomemos conciencia, cuidemos lo que es nuestro. Explora tus opciones con nosotros.</p>
                </MoreLink>
              </div>
            </div>
            <div className='flex justify-center w-full p-2 basis-1/3'>
              <Rural className='w-72 pt-4 sm:pt-0' />
            </div>
          </div>
        </section>

        <section className='bg-white py-16 sm:py-32'>
          <div className='flex flex-col md:flex-row items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center px-3 md:p-6 mb-4 sm:mb-0 text-center sm:text-left basis-2/3'>
              <Title text='Ingeniería Estructural' />
              <div className='text-lg pt-4'>
                <p className='text-gray-600 text-justify sm:text-left'>
                  Nuestro equipo cuenta con su propios ingenieros de estructuras. Esto nos permite encontrar las mejores soluciones estructurales para cada proyecto, desde los más simples a los más complejos, y todo sin alterar el diseño arquitectónico.<br /><br />
                </p>
                <MoreLink setContactFormModalShow={setContactFormModalShow}>
                  <p className='text-gray-600 font-bold text-justify sm:text-left'>¿En qué áreas trabajamos?</p>
                  <ul className='text-gray-600 list-disc ml-8 text-justify sm:text-left mb-4'>
                    <li>Estructuras de hormigón armado y mixtas</li>
                    <li>Diseño de acero y uniones</li>
                    <li>Diseño de madera y vidrio estructural</li>
                    <li>Obras civiles, riegos y drenajes</li>
                    <li>Caminos y Pasarelas</li>
                    <li>Cimentaciones y movimientos de tierra</li>
                    <li>Sótanos y muros de contención</li>
                  </ul>
                </MoreLink>
              </div>
            </div>
            <div className='flex justify-center w-full p-2 basis-1/3'>
              <Structural className='w-72 pt-4 sm:pt-0' />
            </div>
          </div>
        </section>

        <section className='bg-bh-white py-16 sm:py-32'>
          <div className='flex flex-col md:flex-row-reverse items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center mb-4 sm:mb-0 px-3 md:p-6 text-center sm:text-left basis-2/3'>
              <Title text='Pisos y Reformas' />
              <div className='text-lg pt-4'>
                <p className='text-gray-600 text-justify sm:text-left'>
                  Cocinas americanas, habitaciones con vestidor, amplios baños con doble espejo, espacios diáfanos… muchas son las posibilidades para su vivienda. Nosotros le guiaremos hasta encontrar las más adecuadas.<br /><br />
                </p>
                <p className='text-gray-600 text-justify sm:text-left mb-4 sm:mb-8'>
                  Si está pensando en reformar su piso es probable que necesite una Licencia de Obra o algún otro trámite. Asegúrese de que esté dentro del marco legal, consulte con nosotros sin compromiso.<br /><br />Realizamos todo el tipo de trámites para reformas y obras en pisos u otro tipo de viviendas.
                </p>
                <div className='flex flex-col gap-y-0 items-center sm:flex-row sm:gap-y-8'>
                  <a
                    className='mx-auto gradient text-bh-white font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer'
                    href='reformas'
                  >
                    Saber más
                  </a><br />
                  <button
                    className='mx-auto bg-[#fffc31] text-bh-black font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer'
                    onClick={() => setContactFormModalShow(true)}
                  >
                    <span className='hover:underline text-lg'>
                      Contacta
                    </span>
                    ▸
                  </button>
                </div>
              </div>
            </div>
            <div className='flex justify-center w-full p-2 basis-1/3'>
              <Pisos className='w-72 pt-4 sm:pt-0' />
            </div>
          </div>
        </section>

        <section className='bg-white py-16 sm:py-32'>
          <div className='flex flex-col md:flex-row items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center mb-4 sm:mb-0 px-3 md:p-6 text-center sm:text-left basis-2/3'>
              <Title text='Proyectos Fotovoltáicos' />
              <div className='text-lg pt-4'>
                <p className='text-gray-600 text-justify sm:text-left'>
                  En Bluehaus estamos comprometidos con el medioambiente, no solo desde una perspectiva de ahorro, sino también de aprovechamiento óptimo de los recursos.<br /><br />
                </p>
                <MoreLink setContactFormModalShow={setContactFormModalShow}>
                  <p className='text-gray-600 text-justify sm:text-left mb-4'>¿Cuál sería el tamaño óptimo para su instalación fotovoltáica?<br />Nuestro equipo desarrolla previamente un estudio de viabilidad antes de presentarle una propuesta comercial.<br /><br /> De esta forma determinamos la rentabilidad de su instalación fotovoltáica para autoconsumo y el tiempo de amortización.</p>
                </MoreLink>
              </div>
            </div>
            <div className='flex justify-center w-full p-2 basis-1/3'>
              <Solar className='w-72 pt-4 sm:pt-0' />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
