import { ReactComponent as Transition1 } from '../assets/transition1.svg'
import { ReactComponent as Main } from '../assets/main.svg'
import { ReactComponent as Spain } from '../assets/spain.svg'
import { ReactComponent as Globe } from '../assets/globe.svg'
import { ReactComponent as City } from '../assets/city.svg'
import { ReactComponent as Chart } from '../assets/donut-chart.svg'
import { ReactComponent as Construction } from '../assets/construction.svg'
import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import InfoLink from '../components/InfoLink'
import RollingBanner from '../components/RollingBanner'
import Title from '../components/Title'
import Subtitle from '../components/Subtitle'
import ContactFormModal from '../components/ContactFormModal'
import Tracking from '../tracking'
import useTidioChat from '../hooks/useTidioChat'

export default function Landing () {
  const [contactFormModalShow, setContactFormModalShow] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    Tracking()

    function reveal () {
      const reveals = document.querySelectorAll('.reveal')
      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top
        const elementVisible = 50
        if (elementTop < window.innerHeight - elementVisible) {
          reveals[i].classList.add('active')
        }
      }
    }
    window.addEventListener('scroll', reveal)

    // To check the scroll position on page load
    reveal()
  }, [])

  useTidioChat()

  return (
    <div>
      <ContactFormModal showModal={contactFormModalShow} setShowModal={setContactFormModalShow} />
      <NavBar />
      <main className='pt-24'>
        <div className='max-w-5xl py-6 mx-auto flex flex-wrap flex-col md:flex-row items-center'>
          {/* left */}
          <div className='flex flex-col w-full md:w-2/5 items-start text-center md:text-left basis-1/2'>
            <h1 className='my-4 font-bold leading-tight text-5xl sm:text-6xl lg:text-7xl reveal'>¿Buscas un Arquitecto?</h1>
            <p className='w-full leading-normal text-3xl mb-8 reveal'>Nosotros te ayudamos</p>
            <button className=' mx-auto lg:mx-0 bg-[#fffc31] text-bh-black font-bold rounded-full py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out cursor-pointer reveal' onClick={() => setContactFormModalShow(true)}>
              Cuéntanos más
            </button>
          </div>
          {/* right */}
          <div className='w-full py-8 text-center basis-1/2'>
            <Main className='scale-100 hidden md:block lg:block reveal' />
          </div>
        </div>
        <div className='relative -mt-12 lg:-mt-24'>
          <Transition1 />
        </div>

        {/** 2 **/}
        <section className='bg-white py-12 sm:py-32'>
          <div className='flex flex-col md:flex-row items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center px-2 md:p-6 text-center sm:text-left'>
              <Title text='Servicios Completos' />
              <div>
                <Subtitle
                  text='Todo lo que necesites al mejor precio'
                  customCss='sm:mx-0 mx-4'
                />
                <div className='flex flex-row text-blue-600 mb-4 sm:mb-0 justify-center sm:justify-start'>
                  <InfoLink text='Leer Más' url='servicios' margin />
                  <InfoLink text='Contacta' isContacta color='1f33ac' setContactFormModalShow={setContactFormModalShow} />
                </div>
              </div>
            </div>
            <div className='flex justify-center w-full sm:w-1/2 p-2 sm:mt-0 mt-8'>
              <Chart className='w-2/3 mx-auto hover:scale-110 duration-300 ease-in-out' />
            </div>
          </div>
        </section>

        {/** 3 **/}
        <section className='bg-bh-white py-8 sm:py-32'>
          <div className='flex flex-col md:flex-row-reverse items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center px-2 md:p-6 text-center sm:text-right'>
              <Title text='Obra Nueva y Reformas' />
              <div>
                <Subtitle text='Consigue todos los trámites con la mayor facilidad' />
                <div className='flex flex-row text-blue-600 mb-4 justify-center sm:justify-end'>
                  <InfoLink text='Leer Más' url='reformas' margin />
                  <InfoLink text='Contacta' isContacta color='1f33ac' setContactFormModalShow={setContactFormModalShow} />
                </div>
              </div>
            </div>
            <div className='flex justify-center w-full sm:w-1/2 p-2 sm:mt-0 mt-8'>
              <Construction className='w-8/12 sm:w-10/12' />
            </div>
          </div>
        </section>

        {/** 4 **/}
        <section className='bg-white py-12 sm:py-32'>
          <div className='flex flex-col md:flex-row justify-between items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center px-2 md:p-6 text-center sm:text-left'>
              <Title text='¿Dónde estamos?' />
              <div>
                <Subtitle text='Ofrecemos nuestros servicios en toda España' />
                <div className='flex flex-row text-blue-600 mb-4 justify-center sm:justify-start'>
                  <InfoLink text='Contacta' isContacta color='1f33ac' setContactFormModalShow={setContactFormModalShow} />
                </div>
              </div>
            </div>
            <div className='flex justify-center w-full sm:w-1/2 p-2 sm:mt-0 mt-8'>
              <Spain className='fill-dark-blue hover:fill-light-blue h-56 w-56' />
            </div>
          </div>
        </section>

        {/** 5 **/}
        <section className='bg-bh-white py-8 sm:py-32'>
          <div className='flex flex-col md:flex-row-reverse items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center px-2 md:p-6 text-center sm:text-right'>
              <Title text='Are you an Expat?' />
              <div>
                <Subtitle text='Get your project under way with simple and practical advice. All in perfect English!' />
                <div className='flex flex-row text-blue-600 mb-4 justify-center sm:justify-end'>
                  <InfoLink text='Read more' url='expats' margin />
                  <InfoLink text='Contact' isContacta color='1f33ac' setContactFormModalShow={setContactFormModalShow} />
                </div>
              </div>
            </div>
            <div className='flex justify-center w-full sm:w-1/2 p-2 sm:mt-0 mt-8'>
              <Globe className='scale-90' />
            </div>
          </div>
        </section>

        <RollingBanner />

        {/** 7 **/}
        <section className='bg-white py-12 sm:py-32'>
          <div className='flex flex-col md:flex-row items-center mb-6 md:mb-0 max-w-4xl mx-auto reveal'>
            <div className='flex flex-col justify-center sm:w-2/3 md:p-6 text-center sm:text-left'>
              <Title text='Para Inmobiliarias' />
              <div>
                <Subtitle text='Contrata servicios básicos desde 85€ + IVA. Planos, visualizaciones 3D y más.' />
                <div className='flex flex-row text-blue-600 mb-4 sm:mb-0 justify-center sm:justify-start'>
                  <InfoLink text='Contacta' isContacta color='1f33ac' setContactFormModalShow={setContactFormModalShow} />
                </div>
              </div>
            </div>
            <div className='flex justify-center w-full sm:w-1/2 p-2 sm:mt-0 mt-8'>
              <City className='w-48 fill-dark-blue' />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
